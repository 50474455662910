import React  from 'react';
import Div100vh from 'react-div-100vh'
//, { lazy }

import GgwSettings from './components/ggwSettings'
import {printPrev, print, printPrevEnd} from './Print'

import OlMap from 'ol/Map';
import OlView from 'ol/View';

import OlGeoJSON from 'ol/format/GeoJSON';
import OlGPX from 'ol/format/GPX';
//Layer
import OlLayerTile from 'ol/layer/Tile';
import OlLayerVector from 'ol/layer/Vector';
import OlSourceVector from 'ol/source/Vector';
import OlLayerGroup from 'ol/layer/Group';
import OlLayerImage from 'ol/layer/Image';
import OlSourceImageStatic from 'ol/source/ImageStatic';
import OlSourceTileWMS from 'ol/source/TileWMS';
import OlSourceImageWMS from 'ol/source/ImageWMS';
import OlSourceXYZ from 'ol/source/XYZ';

//Source
import OlSourceOsm from 'ol/source/OSM';

import {createStringXY} from 'ol/coordinate';

import OlScaleLine from 'ol/control/ScaleLine';
import OlMousePosition from 'ol/control/MousePosition';
import {defaults as OldefaultControls, Attribution} from 'ol/control';

//Geom
import OlLineString from 'ol/geom/LineString';
import OlPoint from 'ol/geom/Point';

// Interaction
import {defaults as defaultInteractions,  DragRotateAndZoom} from 'ol/interaction';

//Events

//Style
import {Fill, Stroke, Style, Icon, Circle, RegularShape} from 'ol/style';

//ol-ext
import OleFlowLine from 'ol-ext/style/FlowLine';
import OleProfile from 'ol-ext/control/Profile';
import OleHover from 'ol-ext/interaction/Hover';
import {ol_coordinate_dist2d} from "ol-ext/geom/GeomUtils";

//Format
import OlFeature from 'ol/Feature';
//import Polygon from 'ol/geom/Polygon';

//Proj4
import {register} from 'ol/proj/proj4';
import proj4 from 'proj4';
//import {getPointResolution} from 'ol/proj';

import Popup from 'ol-popup';
//
  
/*
import { 
	Card,
	Col, 
	Drawer, 
	Form, 
	Radio,
	Row  } from 'antd';
*/

import {
	GeoLocationButton,
	SimpleButton,
	MeasureButton,
	ZoomToExtentButton   
} from '@terrestris/react-geo';

import { connect } from 'react-redux';
//import { center, extent, attributions, weg, dop_url, dop_layer } from './freigericht';
import { center, extent, attributions, weg, dop_url, dop_layer } from './obertshausen';
//import { center, extent, attributions, weg, dop_url, dop_layer } from './maintal';

//import { jsPDF } from "jspdf";
//import domtoimage from 'dom-to-image-more';

import './App.css';
import 'ol/ol.css';
import 'antd/dist/antd.css';
import './react-geo.css';
import './Profil.css'
//import { ConsoleSqlOutlined } from '@ant-design/icons';


proj4.defs('EPSG:25832','+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs');
register(proj4);

class App extends React.Component {	
	constructor(props) { 
		super(props);
		this.mapDivId = `map-${Math.random()}`;
		var lastFeature;

		//?
		//layers=hth,bplan
		const queryParams = new URLSearchParams(window.location.search);
		//console.log(queryParams);
		var ggParams = queryParams.get('layers');
		//console.log(ggParams);
		var gglayers = [];

		if ( ggParams != null ){
			gglayers = ggParams.split(",");
		}
		//&
		//basis=osm,otm
		ggParams = queryParams.get('basis');
		var ggbasis = [];

		if ( ggParams != null ){
			ggbasis = ggParams.split(",");
		}
		else{
			ggbasis = ['osm'];
		}

		this.state = {
			measureActive: false,
			visibleOSM: false,
			dopState: 'red',
		};
		
		this.extent = extent;
		
		const attribution = new Attribution({
		tipLabel: 'Nutungsbedigungen',
		collapsible: false
		});

		//Das Müsste noch davor:
		//['<a href="https://openlayers.org/">OpenLayers</a> |'])
		
		this.scaleLine = new OlScaleLine({bar: true, text: true, minWidth: 125});

		// OSM
		const osmLayer = new OlLayerTile({
			name: 'Open Street Map',
			label: 'osm',
			digi: false,
			source: new OlSourceOsm({
				attributions: ['<a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | '],
			})
		});	

		//OTM
		const otmLayer = new OlLayerTile({
			name: 'Open Topo Map',
			label: 'otm',
			digi: false,
			//visible: false,
			source: new OlSourceXYZ({
				url: 'https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png',
				attributions: ['<a href="https://opentopomap.org/about">OpenTopoMap</a> | '],
			})
		});
		
		//GPX
		var min, max;
		var pt, feature;

		var style = new Style({
			zIndex:1,
			image: new RegularShape({
				radius: 10,
				radius2: 5,
				points: 5,
				fill: new Fill({ color: 'blue' })
			}),
			stroke: new Stroke({
				color: [255,0,0],
				width: 2
			})
		});

		function getMinMax (feature) {
			feature.getGeometry().getCoordinates()[0].forEach( function(p){
				max = Math.max(max||-Infinity, p[2]);
				min = Math.min(min||Infinity, p[2]);
			});
			max = Math.round(max/10+.4)*10;
			min = Math.round(min/10-.4)*10;
		}

		function getColor(dh) {
			if (dh<128) return [2*dh,160-dh,0];
			else return [ 255, (dh-128)*4, (dh-128)*1.5 ];
		};

		function styleFn(f) {
			return new OleFlowLine({
			visible: false,
			lineCap: 'round',
			color: function(f, step){
				var seg = [];
				var line = f.getGeometry().getLineString(0);
				line.getCoordinateAtSeg(step*line.getLength(), seg);
				var h = (seg[0][2]+seg[0][2])/2;
				var dh = 255*(h-min)/(max-min);
				return getColor(dh);
			},
			width: 5,
			geometry: function (f) {
				if (f.getGeometry().getType() === 'MultiLineString') {
				return f.getGeometry().getLineString(0);
				} else {
				return f.getGeometry();
				}
			}
			})
		};

		OlLineString.prototype.getCoordinateAtSeg = function (r, seg) {
			var c, d;
			if (r < 1e-10) {
				if (seg)  {
					c = this.getCoordinates();
					seg[0] = c[0];
					seg[1] = c[1];
				}
				return this.getFirstCoordinate();
			}
			if (this.getLength()-r < 1e-10) {
				if (seg) {
					c = this.getCoordinates();
					seg[0] = c[c.length-2];
					seg[1] = c[c.length-1];
				}
				return this.getLastCoordinate();
			}
			if (!seg) seg=[];
			var s = 0;
			var coord = this.getCoordinates();
			for (var i=1; i<coord.length; i++) {
				d = ol_coordinate_dist2d(coord[i-1], coord[i]);
				if (s+d >= r) {
					var p0 = seg[0] = coord[i-1];
					var p1 = seg[1] = coord[i];
					d = ol_coordinate_dist2d(p0,p1)
					return [
						p0[0] + (r-s) * (p1[0]-p0[0]) /d,
						p0[1] + (r-s) * (p1[1]-p0[1]) /d
					];
				}
				s += d;
			}
		};

		const gpxSource = new OlSourceVector({
			url: weg[0],
			format: new OlGPX(),
			attributions: ['<a href="https://www.spessart-tourismus.de/impressum">Spessart-Tourismus</a> '],
		});

		const gpxLayer = new OlLayerVector({
			name: 'Wanderweg',
			label: 'weg',
			digi: false,		
			source: gpxSource,
			/*
			style: function (feature) {
			return style[feature.getGeometry().getType()];
			},
			*/
			style: styleFn,
		});

		var profile = new OleProfile({
			title: 'Höhenprofil',
			selectable: false,
			zoomable: false,
			width: 600,
			height: 200,
			style: new Style({
				fill: new Fill({ color: '#ccc' })
			}),
			
			info: {
				'ytitle': 'Höhe (m)',
				'xtitle': 'Entfernung (km)',

				'zmin': 'Höhe (min)',
				'zmax': 'Höhe (max)',
				
				'altitude': 'Höhe',
				'distance': 'Entfernung',
				'time': 'Zeit',
				'altitudeUnits': 'm',
				'distanceUnitsM': 'm',
				'distanceUnitsKM': 'km',								
			},			
		});
		

		gpxSource.once('change',function(e) {
			if (gpxSource.getState() === 'ready'){
				console.log("gpxSource ready");
				feature = gpxSource.getFeatures()[0];
				getMinMax (feature);
				profile.setGeometry(feature);

				let extent = gpxSource.getExtent();
				console.log(extent);
				
				

				profile.show();
				view.animate({
					zoom: 15,
					center: [extent[0] + (extent[2]-extent[0])/2,extent[1] + (extent[3]-extent[1])/2],
					duration: 2000,
				});				
	
			}
		});  	


		// B-Plan
		var bplanStyle = function() {
			return function(feature, resolution) {
				var style = new Style({});
				var status = feature.get('Status');

				//console.log("feature: " + status);	
				if ( status === 'rechtskräftig'){
					style = new Style({
						fill: new Fill({color: 'rgba(0, 0, 0, 0)'}),
						stroke: new Stroke({color: 'rgba(0,0,0,0.6)', width: 3, lineDash: [5, 15, 5, 15]})
					});
				}
				return [style];
			};
		};
		
		var selectbplanStyle = new Style({
			fill: new Fill({color: 'rgba(0, 0, 0, 0)'}),
			stroke: new Stroke({
				color: 'rgba(255,0,0,0.6)', 
				width: 8, 
				lineDash: [5, 15, 5, 15]
			})
		});		

		var bplanVector = new OlSourceVector({
			url: 'Bebauungsplan.geojson',
			format: new OlGeoJSON(),
			attributions: attributions,
		});

		var bplanLayer = new OlLayerVector({
			name: 'Bebauungspläne',
			label: 'bplan',
			digi: true,
			source: bplanVector,
			style: bplanStyle(),		
		});
		
		var imageLayer = new OlLayerImage({
			name: 'react-geo_bplan_image',
			opacity: 0.7,
		});	
		
		
		//Hundetütenhalter
		
		var hthStyle = new Style({
			image: new Icon({
				scale: [0.08, 0.08],
				anchor: [0.5, 1],
				src: 'hth.png',
			}),
		});			

		var selecththStyle = new Style({
			image: new Icon({
				scale: [0.08, 0.08],
				anchor: [0.5, 1],
				src: 'sel_hth.png',
			}),
		});		
		
		var hthVector = new OlSourceVector({
			url: 'Hundetuetenhalter.geojson',
			format: new OlGeoJSON(),
			attributions: attributions,
		});

		var hthLayer = new OlLayerVector({
			name: 'Hundekottütenhalter',
			label: 'hth',
			digi: true,
			source: hthVector,
			style: hthStyle,		
		});

		//Defibrillatoren
			
		var defiStyle = new Style({
			image: new Icon({
				scale: [0.11, 0.11],
				anchor: [0,1],
				src: 'defi.png',
			}),
		});			

		var selectdefiStyle = new Style({
			image: new Icon({
				scale: [0.11, 0.11],
				anchor: [0,1],
				src: 'sel_defi.png',
			}),
		});		
		
		var defiVector = new OlSourceVector({
			url: 'Defibrillatoren.geojson',
			format: new OlGeoJSON(),
			attributions:  [['<a href="http://definetz.online">DefiNetz</a> | '],attributions],
		});

		var defiLayer = new OlLayerVector({
			name: 'Defibrillator',
			label: 'defi',
			digi: true,
			source: defiVector,
			style: defiStyle,		
		});		

		//Sitzbank
		
		var bankStyle = new Style({
			image: new Icon({
				scale: [0.08, 0.08],
				anchor: [0.5, 1],
				src: 'bank.png',
			}),
		});			

		var selectbankStyle = new Style({
			image: new Icon({
				scale: [0.08, 0.08],
				anchor: [0.5, 1],
				src: 'sel_bank.png',
			}),
		});		
		
		var bankVector = new OlSourceVector({
			url: 'Sitzbank.geojson',
			format: new OlGeoJSON(),
			attributions: attributions,
		});

		var bankLayer = new OlLayerVector({
			name: 'Sitzbank',
			label: 'bank',
			digi: true,
			source: bankVector,
			style: bankStyle,		
		});	

		const borisLayer = new OlLayerImage({
			name: 'BORIS',
			label: 'boris',
			digi: false,
			opacity: 0.6,
			source: new OlSourceImageWMS({
				url: 'https://www.gds-srv.hessen.de/cgi-bin/lika-services/ogc-free-maps.ows',
				params: {
				'LAYERS': 'hboris_zonen,hboris_feature,hboris_label',
				'FORMAT': 'image/jpeg',
				},
				attributions: ['<a href="http://www.geoportal.hessen.de">GDI-Hessen</a> '],
				serverType: 'mapserver',
			})
		});
		
		//DOP

		const dopSource = new OlSourceTileWMS({
		//const dopSource = new OlSourceImageWMS({			
			url: dop_url,
			params: {'LAYERS': dop_layer,'TILED': true},
			projection: 'EPSG:25832',
			serverType: 'geoserver',
			//crossOrigin: 'anonymous'
		});

		const dopLayer = new OlLayerTile({
		//const dopLayer = new OlLayerImage({
			name: 'DOP',
			'title': 'DOP',
			label: 'dop',
			digi: false,			
			source: dopSource,   
			visible: false
		});		
		

		// Print
		this.printSource = new OlSourceVector();

		var printLayer = new OlLayerVector({
			name: 'react-geo_print',
			source: this.printSource,
			style: new Style({
				fill: new Fill({
					color: 'rgba(255, 255, 255, 0.3)',
				}),
				stroke: new Stroke({
					color: 'rgb(255,0,0)',
					width: 4,
				}),
				image: new Circle({
					radius: 20,
					fill: new Fill({
						color: 'rgba(255,0,0,0.3)', 
					}),
				})			
			})			
		});	
		
		const view = new OlView({
			projection: 'EPSG:25832',
			center: center,
			zoom: 14,
			//zoom: 20,
		});
			
		this.layerGroup = new OlLayerGroup({
		name: 'Layergroup',
		layers:  []
		});
		
		this.map = new OlMap({
			view: view,
			controls: OldefaultControls({ attribution: true }).extend([
				attribution,
				this.scaleLine,
				new OlMousePosition({
					coordinateFormat: createStringXY(2),
					//projection: projection
				})	
			]),
			interactions: defaultInteractions().extend([
				new DragRotateAndZoom()
			]),     
			layers: this.layerGroup
		});

		if ( gglayers.includes("dop") ){
			console.log("addLayer dop");
			this.map.addLayer(dopLayer);
			this.state = { dopState: 'white' };
		}

		if ( ggbasis.includes("osm") ){
			this.map.addLayer(osmLayer);
		}	
		if ( ggbasis.includes("otm") ){
			this.map.addLayer(otmLayer);
		}		

		if ( gglayers.includes("hth") ){
			this.map.addLayer(hthLayer);
		}
		if ( gglayers.includes("bank") ){
			this.map.addLayer(bankLayer);
		}
		if ( gglayers.includes("defi") ){
			this.map.addLayer(defiLayer);
		}		
		if ( gglayers.includes("weg") ){
			console.log("addLayer weg");
			this.map.addLayer(gpxLayer);

			pt = new OlFeature(new OlPoint([0,0]));

			var overlay = new OlLayerVector({ source: new OlSourceVector() });
			overlay.getSource().addFeature(pt);
			overlay.setMap(this.map);

			// console.log(profile.info);
			this.map.addControl(profile);

			profile.on(["over","out"], function(e){
				if (!pt) return;
				if (e.type === "over"){
				// Show point at coord
					pt.setGeometry(new OlPoint(e.coord));
					pt.setStyle(style);
					profile.popup(e.coord[2]+" m");
				} else {
				// hide point
					pt.setStyle([]);
				}
			});
			
			var hover = new OleHover({ cursor: "pointer", hitTolerance:10 });
			this.map.addInteraction(hover);

			hover.on("hover", function(e) {
				var c = feature.getGeometry().getClosestPoint(e.coordinate);
				drawPoint({ type: "over", coord: c });
				var p = profile.showAt(e.coordinate);
				profile.popup(p[2]+" m");
			});

			hover.on("leave", function(e) {
				profile.popup();
				profile.showAt();
				drawPoint({});
			});

			function drawPoint(e) {
				if (!pt) return;
				if (e.type==="over"){
					pt.setGeometry(new OlPoint(e.coord));
					pt.setStyle(style);
				} 
				else {
					pt.setStyle([]);
				}
			};

		}					
		if ( gglayers.includes("bplan") ){
			console.log("addLayer bplan");
			this.map.addLayer(bplanLayer);
			this.map.addLayer(imageLayer);
		}	
		
		if ( gglayers.includes("boris") ){
			console.log("addLayer boris");
			this.map.addLayer(borisLayer);
		}
		

		this.map.addLayer(printLayer);

		//console.log(attribution) ;
		var popup = new Popup();
		this.map.addOverlay(popup);	
		

		this.map.on('click', evt => {
			
			console.log("singleclick ",evt.pixel);  
			if (!this.state.measureActive){
				var selected = [];
				var tmpFeature;
				var coord;
				
				this.map.forEachFeatureAtPixel(evt.pixel, function (feature, layer) {
					feature.layer = layer.get('label');
					selected.push(feature);
				});
				
				console.log("Last ",lastFeature);
				
				if ( selected.length === 0 ) {
					if  ( lastFeature != null ){
						console.log("lastFeature ",lastFeature.layer);
						if ( lastFeature.layer === "bplan" ){
							imageLayer.setSource();
						}					
						lastFeature.setStyle(undefined);
						lastFeature = null;
						popup.hide();
					}
				}
				else{
					selected.forEach(function(feature){
						
						if ( feature !== lastFeature ){
							console.log("selected ",feature);
							if  ( tmpFeature == null ){
								if  ( lastFeature != null ){
									if ( lastFeature.layer === "bplan" ){
										imageLayer.setSource();
									}													
									lastFeature.setStyle(undefined);
								}
								tmpFeature = feature;
								
								if ( feature.layer === "hth" ){
									feature.setStyle(selecththStyle);
									coord = feature.getGeometry().getCoordinates();
									popup.show(coord, '<div><mark><b>Hundekottütenstation:</b></mark><small>&nbsp;<div id="hth_gemarkung">&nbsp;</div><div id="hth_lage">&nbsp;</div></p></small></div>');


									var hth_gemarkung = document.getElementById('hth_gemarkung');
									if (hth_gemarkung) {
										hth_gemarkung.innerHTML = 'Gemarkung: ' + feature.get('Gemarkung');
									} else {
										hth_gemarkung.innerHTML = '&nbsp;';
									}							

									var hth_lage = document.getElementById('hth_lage');
									if (hth_lage) {
										hth_lage.innerHTML = 'Lage: ' + feature.get('Lage');
									} else {
										hth_lage.innerHTML = '&nbsp;';
									}							
								}
								if ( feature.layer === "bank" ){
									feature.setStyle(selectbankStyle);
									coord = feature.getGeometry().getCoordinates();
									popup.show(coord, '<div><mark><b>Sitzbank</b></mark><small>&nbsp;<div id="bank_typ">&nbsp;</div></p></small></div>');


									var bank_typ = document.getElementById('bank_typ');
									if (bank_typ) {
										bank_typ.innerHTML = 'Typ: ' + feature.get('Typ');
									} else {
										bank_typ.innerHTML = '&nbsp;';
									}							
								}
								if ( feature.layer === "defi" ){
									feature.setStyle(selectdefiStyle);
									coord = feature.getGeometry().getCoordinates();
									popup.show(coord, '<div><mark><b>Defibrillator</b></mark><small>&nbsp;<div id="defi_standort">&nbsp;</div><div id="defi_adresse">&nbsp;</div><div id="defi_plzort">&nbsp;</div></p></small></div>');

									var defi_standort = document.getElementById('defi_standort');
									if (defi_standort) {
										defi_standort.innerHTML = 'Standort: ' + feature.get('Standort');
									} else {
										defi_standort.innerHTML = '&nbsp;';
									}
									
									var defi_adresse = document.getElementById('defi_adresse');
									if (defi_adresse) {
										defi_adresse.innerHTML = 'Adresse: ' + feature.get('Adresse');
									} else {
										defi_adresse.innerHTML = '&nbsp;';
									}							

									var defi_plzort = document.getElementById('defi_plzort');
									if (defi_plzort) {
										defi_plzort.innerHTML = 'PLZ Ort: ' + feature.get('PLZ_Ort');
									} else {
										defi_plzort.innerHTML = '&nbsp;';
									}							
									
								}																						
								if ( feature.layer === "bplan" ){
									feature.setStyle(selectbplanStyle);
									
									var url = "./BPlan/"+feature.get('Raster_Raster');
									var original = "../BPlanO/"+feature.get('Original_Pfad');
									var minx = feature.get('MinX');
									var miny = feature.get('MinY');
									var maxx = feature.get('MaxX');
									var maxy = feature.get('MaxY');

									coord = [minx, miny];
									popup.show(coord, '<div><mark><b>Bebauungsplan:</b></mark><small>&nbsp;<div id="bplan_nummer">&nbsp;</div><div id="bplan_bezeichnung">&nbsp;</div><div id="bplan_rechtsgueltig">&nbsp;</div><a id="bplan_download" href=""  target="_blank">&nbsp;</a></p></small></div>');

									console.log(url);

									var source = new OlSourceImageStatic({
										url : url,
										projection: 'EPSG:25832',
										imageExtent: [minx,miny,maxx,maxy]
									});
									imageLayer.setSource(source);

									var bplan_nummer = document.getElementById('bplan_nummer');
									if (bplan_nummer) {
										bplan_nummer.innerHTML = 'Nummer: ' + feature.get('Nummer');
									} else {
										bplan_nummer.innerHTML = '&nbsp;';
									}							

									var bplan_bezeichnung = document.getElementById('bplan_bezeichnung');
									if (bplan_bezeichnung) {
										bplan_bezeichnung.innerHTML = 'Bemerkung: ' + feature.get('Bezeichnung');
									} else {
										bplan_bezeichnung.innerHTML = '&nbsp;';
									}

									var bplan_rechtsgueltig = document.getElementById('bplan_rechtsgueltig');
									if (bplan_rechtsgueltig) {
										bplan_rechtsgueltig.innerHTML = 'Rechtsgültig seit: ' + feature.get('Rechtsgueltig');
									} else {
										bplan_rechtsgueltig.innerHTML = '&nbsp;';
									}							

									var bplan_download = document.getElementById('bplan_download');
									if ( bplan_download ){
									bplan_download.innerHTML = 'Originalplan';
									bplan_download.href = original;
									}								
								}
							}
						}
						else {
							console.log("feature = last ",feature);						
							if ( selected.length > 1 ) {
								feature.setStyle(undefined);
							}
							else {
								tmpFeature = feature; 
							}
						}
					});
					lastFeature = tmpFeature;
				}			
			}
		});

		this.setMeasure = this.setMeasure.bind(this);
		this.printPrev = printPrev.bind(this);
		this.print = print.bind(this);
		this.printPrevEnd = printPrevEnd.bind(this);

		this.detect = () => {
			console.log("Orientation change");
			setTimeout(this.updateSize, 100);
		};	
		
		this.dopOnOff = () => {
			var visible;
			let visibleOSM;

			visible = dopLayer.getVisible();
			
			if ( !visible ){
				dopLayer.setVisible(!visible);
				visibleOSM = osmLayer.getVisible();
				this.setState({
					visibleOSM
				});
				if ( visibleOSM ){											
					osmLayer.setVisible(false);			
				}
			}
			else {
				dopLayer.setVisible(!visible);
				osmLayer.setVisible(this.state.visibleOSM);
			}
		};		

		this.updateSize = () => {
			this.map.updateSize();			
		};
	}
  
	setMeasure = (pressed) => {
		this.setState({
			measureActive: pressed
		});
	}

	componentDidMount() {
		this.map.setTarget(this.mapDivId);
		this.detectListener = window.addEventListener("resize", this.detect);
	}

	render() {

		console.log("render()");
		
		return(
			<div>
				<Div100vh	
					id={this.mapDivId}
				/>
				<div>
					<ZoomToExtentButton
						style={{position: 'fixed', top: '90px', left: '10px'}}
						map={this.map}
						extent={this.extent}
						shape="circle"
						iconName="home"
						tooltip="Nach Hause telefonieren ..."
						tooltipPlacement="left"	
						fitOptions={{
							duration: 3000
						}}
					/>		
				</div>
				<div>
					{this.props.measureType === 'polygon' && (
						<MeasureButton
							key="measurePolygon"
							name="measurePolygon"
							map={this.map}
							pressed={this.state.measureActive}
							onToggle={pressed => this.setMeasure(pressed)}
							measureType="polygon"
							iconName="pencil"
							pressedIconName="pencil"
							shape="circle"
							tooltip="Polygon Messen"
							continueLineMsg="Wählen Sie den nächsten Punkt"
							showHelpTooltip={false}
							tooltipPlacement="left"				  
							style={{position: 'fixed', top: '130px', left: '10px'}}
						/>
					)}
					{this.props.measureType === 'line' && (
						<MeasureButton
							key="measureLine"
							name="measureLine"
							map={this.map}
							pressed={this.state.measureActive}
							onToggle={pressed => this.setMeasure(pressed)}
							measureType="line"
							iconName="pencil"
							pressedIconName="pencil"
							shape="circle"
							tooltip="Line Messen"
							continueLineMsg="Wählen Sie den nächsten Punkt"
							showHelpTooltip={false}
							tooltipPlacement="left"				  
							style={{position: 'fixed', top: '130px', left: '10px'}}
						/>
					)}
					{this.props.measureType === 'angle' && (
						<MeasureButton
							key="measureAngle"
							name="measureAngle"
							map={this.map}
							pressed={this.state.measureActive}
							onToggle={pressed => this.setMeasure(pressed)}
							measureType="angle"
							iconName="pencil"
							pressedIconName="pencil"
							shape="circle"
							tooltip="Winkel Messen"
							continueLineMsg="Wählen Sie den nächsten Punkt"
							showHelpTooltip={false}
							tooltipPlacement="left"				  
							style={{position: 'fixed', top: '130px', left: '10px'}}
						/>
					)}			
				</div>
				<div>
					<GgwSettings
						style={{position: 'fixed', top: '170px', left: '10px'}}
						map={this.map} 
						tooltipPlacement="right"
					/>
				</div>
				<div>
					<SimpleButton
						style={{position: 'fixed', top: '210px', left: '10px'}}
						shape="circle"
						onClick={this.print}
						onMouseEnter={this.printPrev}
						onTouchStart={this.printPrev}
						onMouseLeave={this.printPrevEnd}
						onTouchEnd={this.printPrevEnd}
						iconName="print"
						tooltip="Drucken"
						tooltipPlacement="left"				
					/>
				</div>
				<div>
					<GeoLocationButton
						style={{position: 'fixed', top: '250px', left: '10px'}}
						shape="circle"
						iconName="location-arrow"
						pressedIconName="crosshairs"
						tooltip="GPS ein/aus"
						tooltipPlacement="left"
						onGeolocationChange={() => undefined}
						map={this.map}
						showMarker={true}
						follow={this.props.followGPS}
					/>	
				</div>
				<div>
					{ this.state.dopState === 'white' && (
						<SimpleButton
							style={{position: 'fixed', top: '290px', left: '10px', color: this.state.dopState}}
							shape="circle"
							onClick={this.dopOnOff}
							iconName="plane"
							tooltip="DOP/OSM"
							tooltipPlacement="left"				
						/>
					)}
			</div>										
			</div>
		)
	};
}

let mapStateToProps = (state) => {
return {
		measureType: state.measureType,
		printScale: state.printScale,
		printSize: state.printSize,
		printOrientation: state.printOrientation,
		followGPS: state.followGPS,
		digiType: state.digiType
	}
}

let mapDispatchToProps = {
}

let AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
  
export default AppContainer;